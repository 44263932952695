import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function PaginationAllPrimaryPreview(props) {
  const {
    PaginationPrimaryBasic,
    PaginationPrimaryComplex,
    PaginationPrimaryElevated,
    PaginationPrimaryHelperText,
  } = props

  return (
    <div className="flex w-full flex-col items-center justify-center gap-20 ">
      {/*<!-- Component: All primary pagination --> */}
      {/*<!-- Basic --> */}
      <CopyComponent
        copyToClipboardCode={PaginationPrimaryBasic}
        componentName="PaginationPrimaryBasic"
      >
        <nav role="navigation" aria-label="Pagination Navigation">
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-01 desc-01"
                >
                  <title id="title-01">Previous page</title>
                  <desc id="desc-01">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap rounded bg-emerald-500 px-4 text-sm font-medium text-white ring-offset-2 transition duration-300 hover:bg-emerald-600 hover:stroke-emerald-500 focus:bg-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-02 desc-02"
                >
                  <title id="title-02">Next page</title>
                  <desc id="desc-02">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- Complex --> */}
      <CopyComponent
        copyToClipboardCode={PaginationPrimaryComplex}
        componentName="PaginationPrimaryComplex"
      >
        <nav role="navigation" aria-label="Pagination Navigation">
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">First</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-03 desc-03"
                >
                  <title id="title-03">First page</title>
                  <desc id="desc-03">link to first page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Previous</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-04 desc-04"
                >
                  <title id="title-04">Previous page</title>
                  <desc id="desc-04">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Current Page, Page 2"
                aria-current="true"
                className="hidden h-10 items-center justify-center whitespace-nowrap rounded bg-emerald-500 px-4 text-sm font-medium text-white ring-offset-2 transition duration-300 hover:bg-emerald-600 hover:stroke-emerald-500 focus:bg-emerald-700 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
                aria-label="Goto Page 3"
              >
                3
              </a>
            </li>
            <li>
              <span
                href="javascript:void(0)"
                className="pointer-events-none hidden h-10 items-center justify-center rounded px-4 text-sm font-medium text-slate-700 focus-visible:outline-none md:inline-flex"
              >
                ...
              </span>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 97"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                97
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 98"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                98
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 99"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                99
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="inline-flex h-10 items-center justify-center gap-4 rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-05 desc-05"
                >
                  <title id="title-05">Next page</title>
                  <desc id="desc-05">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 99"
                className="inline-flex h-10 items-center justify-center gap-4 rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Last</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-06 desc-06"
                >
                  <title id="title-06">Last page</title>
                  <desc id="desc-06">link to last page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- With helper text --> */}
      <CopyComponent
        copyToClipboardCode={PaginationPrimaryHelperText}
        componentName="PaginationPrimaryHelperText"
        extraClasses="w-full"
      >
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          className="flex w-full flex-col items-center gap-2 md:flex-row"
        >
          <p className="flex-1 text-center text-sm text-slate-500 md:text-left">
            Showing 31 to 40 of 57 results
          </p>
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-07 desc-07"
                >
                  <title id="title-07">Previous page</title>
                  <desc id="desc-07">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap rounded bg-emerald-500 px-4 text-sm font-medium text-white ring-offset-2 transition duration-300 hover:bg-emerald-600 hover:stroke-emerald-500 focus:bg-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-08 desc-08"
                >
                  <title id="title-08">Next</title>
                  <desc id="desc-08">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- Elevated --> */}
      <CopyComponent
        copyToClipboardCode={PaginationPrimaryElevated}
        componentName="PaginationPrimaryElevated"
      >
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          className="flex items-center overflow-hidden rounded shadow-md shadow-slate-200"
        >
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-13 desc-13"
                >
                  <title id="title-13">Previous page</title>
                  <desc id="desc-13">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap bg-emerald-500 px-4 text-sm font-medium text-white ring-offset-2 transition duration-300 hover:bg-emerald-600 hover:stroke-emerald-500 focus:bg-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-14 desc-14"
                >
                  <title id="title-14">Next page</title>
                  <desc id="desc-14">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>
      {/*<!-- End All primary pagination --> */}
    </div>
  )
}
