import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function PaginationAllUnderlinePreview(props) {
  const {
    PaginationUnderlineBasic,
    PaginationUnderlineComplex,
    PaginationUnderlineElevated,
    PaginationUnderlineHelperText,
  } = props

  return (
    <div className="flex w-full flex-col items-center justify-center gap-20">
      {/*<!-- Component: All underline pagination --> */}
      {/*<!-- Basic --> */}
      <CopyComponent
        copyToClipboardCode={PaginationUnderlineBasic}
        componentName="PaginationUnderlineBasic"
      >
        <nav role="navigation" aria-label="Pagination Navigation">
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-15 desc-15"
                >
                  <title id="title-15">Previous page</title>
                  <desc id="desc-15">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap border-b border-b-emerald-500 px-4 text-sm font-medium text-emerald-500 transition duration-300 hover:border-b-emerald-600 hover:bg-emerald-50 hover:text-emerald-600 focus:border-b-emerald-700 focus:bg-emerald-50 focus:text-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-16 desc-16"
                >
                  <title id="title-16">Next page</title>
                  <desc id="desc-16">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- Complex --> */}
      <CopyComponent
        copyToClipboardCode={PaginationUnderlineComplex}
        componentName="PaginationUnderlineComplex"
      >
        <nav role="navigation" aria-label="Pagination Navigation">
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">First</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-17 desc-17"
                >
                  <title id="title-17">First page</title>
                  <desc id="desc-17">link to first page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Previous</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-18 desc-18"
                >
                  <title id="title-18">Previous page</title>
                  <desc id="desc-18">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Current Page, Page 2"
                aria-current="true"
                className="hidden h-10 items-center justify-center whitespace-nowrap border-b border-b-emerald-500 px-4 text-sm font-medium text-emerald-500 transition duration-300 hover:border-b-emerald-600 hover:bg-emerald-50 hover:text-emerald-600 focus:border-b-emerald-700 focus:bg-emerald-50 focus:text-emerald-700 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
                aria-label="Goto Page 3"
              >
                3
              </a>
            </li>
            <li>
              <span
                href="javascript:void(0)"
                className="pointer-events-none hidden h-10 items-center justify-center px-4 text-sm font-medium text-slate-700 focus-visible:outline-none md:inline-flex"
              >
                ...
              </span>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 97"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                97
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 98"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                98
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 99"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                99
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-19 desc-19"
                >
                  <title id="title-19">Next page</title>
                  <desc id="desc-19">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 99"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Last</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-20 desc-20"
                >
                  <title id="title-20">Last page</title>
                  <desc id="desc-20">link to last page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- With helper text --> */}
      <CopyComponent
        copyToClipboardCode={PaginationUnderlineHelperText}
        componentName="PaginationUnderlineHelperText"
      >
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          className="flex w-full flex-col items-center gap-2 md:flex-row"
        >
          <p className="flex-1 text-center text-sm text-slate-500 md:text-left">
            Showing 31 to 40 of 57 results
          </p>
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-21 desc-21"
                >
                  <title id="title-21">Previous page</title>
                  <desc id="desc-21">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap border-b border-b-emerald-500 px-4 text-sm font-medium text-emerald-500 transition duration-300 hover:border-b-emerald-600 hover:bg-emerald-50 hover:text-emerald-600 focus:border-b-emerald-700 focus:bg-emerald-50 focus:text-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-22 desc-22"
                >
                  <title id="title-22">Next</title>
                  <desc id="desc-22">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- Elevated --> */}
      <CopyComponent
        copyToClipboardCode={PaginationUnderlineElevated}
        componentName="PaginationUnderlineElevated"
      >
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          className="flex items-center overflow-hidden rounded shadow-md shadow-slate-200"
        >
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-23 desc-23"
                >
                  <title id="title-23">Previous page</title>
                  <desc id="desc-23">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap border-b border-b-emerald-500 px-4 text-sm font-medium text-emerald-500 transition duration-300 hover:border-b-emerald-600 hover:bg-emerald-50 hover:text-emerald-600 focus:border-b-emerald-700 focus:bg-emerald-50 focus:text-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-24 desc-24"
                >
                  <title id="title-24">Next page</title>
                  <desc id="desc-24">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>
      {/*<!-- End All underline pagination --> */}
    </div>
  )
}
