import React, { useState } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import PaginationHeroComponent from "../../../library/components/pagination/react/_preview/PaginationHero"
import PaginationAllPrimaryPreview from "../../../library/components/pagination/react/_preview/primary"
import PaginationAllOverlinePreview from "../../../library/components/pagination/react/_preview/overline"
import PaginationAllUnderlinePreview from "../../../library/components/pagination/react/_preview/underline"
import PaginationAllOutlinePreview from "../../../library/components/pagination/react/_preview/outline"
import PaginationAllMinimalPreview from "../../../library/components/pagination/react/_preview/minimal"

// Primary
const PaginationPrimaryBasicJsx = require("!!raw-loader!../../../library/components/pagination/react/primary/basic.jsx")
const PaginationPrimaryBasicHTML = require("!!raw-loader!../../../library/components/pagination/html/primary/basic.html")

const PaginationPrimaryComplexJsx = require("!!raw-loader!../../../library/components/pagination/react/primary/complex.jsx")
const PaginationPrimaryComplexHTML = require("!!raw-loader!../../../library/components/pagination/html/primary/complex.html")

const PaginationPrimaryElevatedJsx = require("!!raw-loader!../../../library/components/pagination/react/primary/elevated.jsx")
const PaginationPrimaryElevatedHTML = require("!!raw-loader!../../../library/components/pagination/html/primary/elevated.html")

const PaginationPrimaryHelperTextJsx = require("!!raw-loader!../../../library/components/pagination/react/primary/helper_text.jsx")
const PaginationPrimaryHelperTextHTML = require("!!raw-loader!../../../library/components/pagination/html/primary/helper_text.html")

// Overline
const PaginationOverlineBasicJsx = require("!!raw-loader!../../../library/components/pagination/react/overline/basic.jsx")
const PaginationOverlineBasicHTML = require("!!raw-loader!../../../library/components/pagination/html/overline/basic.html")

const PaginationOverlineComplexJsx = require("!!raw-loader!../../../library/components/pagination/react/overline/complex.jsx")
const PaginationOverlineComplexHTML = require("!!raw-loader!../../../library/components/pagination/html/overline/complex.html")

const PaginationOverlineElevatedJsx = require("!!raw-loader!../../../library/components/pagination/react/overline/elevated.jsx")
const PaginationOverlineElevatedHTML = require("!!raw-loader!../../../library/components/pagination/html/overline/elevated.html")

const PaginationOverlineHelperTextJsx = require("!!raw-loader!../../../library/components/pagination/react/overline/helper_text.jsx")
const PaginationOverlineHelperTextHTML = require("!!raw-loader!../../../library/components/pagination/html/overline/helper_text.html")

// Underline
const PaginationUnderlineBasicJsx = require("!!raw-loader!../../../library/components/pagination/react/underline/basic.jsx")
const PaginationUnderlineBasicHTML = require("!!raw-loader!../../../library/components/pagination/html/underline/basic.html")

const PaginationUnderlineComplexJsx = require("!!raw-loader!../../../library/components/pagination/react/underline/complex.jsx")
const PaginationUnderlineComplexHTML = require("!!raw-loader!../../../library/components/pagination/html/underline/complex.html")

const PaginationUnderlineElevatedJsx = require("!!raw-loader!../../../library/components/pagination/react/underline/elevated.jsx")
const PaginationUnderlineElevatedHTML = require("!!raw-loader!../../../library/components/pagination/html/underline/elevated.html")

const PaginationUnderlineHelperTextJsx = require("!!raw-loader!../../../library/components/pagination/react/underline/helper_text.jsx")
const PaginationUnderlineHelperTextHTML = require("!!raw-loader!../../../library/components/pagination/html/underline/helper_text.html")

// Outline
const PaginationOutlineBasicJsx = require("!!raw-loader!../../../library/components/pagination/react/outline/basic.jsx")
const PaginationOutlineBasicHTML = require("!!raw-loader!../../../library/components/pagination/html/outline/basic.html")

const PaginationOutlineComplexJsx = require("!!raw-loader!../../../library/components/pagination/react/outline/complex.jsx")
const PaginationOutlineComplexHTML = require("!!raw-loader!../../../library/components/pagination/html/outline/complex.html")

const PaginationOutlineElevatedJsx = require("!!raw-loader!../../../library/components/pagination/react/outline/elevated.jsx")
const PaginationOutlineElevatedHTML = require("!!raw-loader!../../../library/components/pagination/html/outline/elevated.html")

const PaginationOutlineHelperTextJsx = require("!!raw-loader!../../../library/components/pagination/react/outline/helper_text.jsx")
const PaginationOutlineHelperTextHTML = require("!!raw-loader!../../../library/components/pagination/html/outline/helper_text.html")

// Minimal
const PaginationMinimalBasicIconsTextJsx = require("!!raw-loader!../../../library/components/pagination/react/minimal/basic-icons_text.jsx")
const PaginationMinimalBasicIconsTextHTML = require("!!raw-loader!../../../library/components/pagination/html/minimal/basic-icons_text.html")

const PaginationMinimalBasicIconsJsx = require("!!raw-loader!../../../library/components/pagination/react/minimal/basic-icons.jsx")
const PaginationMinimalBasicIconsHTML = require("!!raw-loader!../../../library/components/pagination/html/minimal/basic-icons.html")

const PaginationMinimalOutlineIconsTextJsx = require("!!raw-loader!../../../library/components/pagination/react/minimal/outline-icons_text.jsx")
const PaginationMinimalOutlineIconsTextHTML = require("!!raw-loader!../../../library/components/pagination/html/minimal/outline-icons_text.html")

const PaginationMinimalOutlineIconsJsx = require("!!raw-loader!../../../library/components/pagination/react/minimal/outline-icons.jsx")
const PaginationMinimalOutlineIconsHTML = require("!!raw-loader!../../../library/components/pagination/html/minimal/outline-icons.html")

const PaginationPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "PaginationAllPrimary",
      title: "Primary pagination",
      active_tab: 1,
    },
    {
      component_name: "PaginationAllOverline",
      title: "Overline",
      active_tab: 1,
    },
    {
      component_name: "PaginationAllUnderline",
      title: "Underline",
      active_tab: 1,
    },
    {
      component_name: "PaginationAllOutline",
      title: "Outline",
      active_tab: 1,
    },
    {
      component_name: "PaginationAllMinimal",
      title: "Minimal",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Pagination - WindUI Component Library"
        ogtitle="Tailwind CSS Pagination - WindUI Component Library"
        description="Pagination components show the current position and enables direct access to previous and subsequent content items. Built with Tailwind CSS by WindUI."
        ogdescription="Pagination components show the current position and enables direct access to previous and subsequent content items. Built with Tailwind CSS by WindUI."
        url="components/pagination/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Pagination, Pagination, Pagination Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />

      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Pagination</h1>
          <p>
            Pagination communicates the number of elements (pages, posts etc)
            that can be loaded within a given context. It shows the current
            position and enables direct access to previous and subsequent
            content items.
          </p>

          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
              <PaginationHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* PaginationOverline */}
          <h3 id="PaginationAllPrimary">Primary Pagination</h3>
          <p>
            Staring from top, basic pagination, pagination with all options,
            pagination with helper text and elevated pagination.{" "}
          </p>
          <PreviewBlock
            id="PaginationAllPrimary"
            HtmlComponent={PaginationPrimaryBasicHTML.default}
            JsxComponent={PaginationPrimaryBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <PaginationAllPrimaryPreview
                PaginationPrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? PaginationPrimaryBasicHTML.default
                    : PaginationPrimaryBasicJsx.default
                }
                PaginationPrimaryComplex={
                  activeTabs[0].active_tab === 1
                    ? PaginationPrimaryComplexHTML.default
                    : PaginationPrimaryComplexJsx.default
                }
                PaginationPrimaryElevated={
                  activeTabs[0].active_tab === 1
                    ? PaginationPrimaryElevatedHTML.default
                    : PaginationPrimaryElevatedJsx.default
                }
                PaginationPrimaryHelperText={
                  activeTabs[0].active_tab === 1
                    ? PaginationPrimaryHelperTextHTML.default
                    : PaginationPrimaryHelperTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Pagination Overline*/}
          <h3 id="PaginationAllOverline">Pagination Overline</h3>
          <p>
            Staring from top, basic pagination, pagination with all options,
            pagination with helper text and elevated pagination.{" "}
          </p>
          <PreviewBlock
            id="PaginationAllOverline"
            HtmlComponent={PaginationOverlineBasicHTML.default}
            JsxComponent={PaginationOverlineBasicJsx.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <PaginationAllOverlinePreview
                PaginationOverlineBasic={
                  activeTabs[1].active_tab === 1
                    ? PaginationOverlineBasicHTML.default
                    : PaginationOverlineBasicJsx.default
                }
                PaginationOverlineComplex={
                  activeTabs[1].active_tab === 1
                    ? PaginationOverlineComplexHTML.default
                    : PaginationOverlineComplexJsx.default
                }
                PaginationOverlineElevated={
                  activeTabs[1].active_tab === 1
                    ? PaginationOverlineElevatedHTML.default
                    : PaginationOverlineElevatedJsx.default
                }
                PaginationOverlineHelperText={
                  activeTabs[1].active_tab === 1
                    ? PaginationOverlineHelperTextHTML.default
                    : PaginationOverlineHelperTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/*  Pagination Underline  */}
          <h3 id="PaginationAllUnderline">Pagination Underline</h3>
          <p>
            Staring from top, basic pagination, pagination with all options,
            pagination with helper text and elevated pagination.{" "}
          </p>
          <PreviewBlock
            id="PaginationAllUnderline"
            HtmlComponent={PaginationUnderlineBasicHTML.default}
            JsxComponent={PaginationUnderlineBasicJsx.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <PaginationAllUnderlinePreview
                PaginationUnderlineBasic={
                  activeTabs[2].active_tab === 1
                    ? PaginationUnderlineBasicHTML.default
                    : PaginationUnderlineBasicJsx.default
                }
                PaginationUnderlineComplex={
                  activeTabs[2].active_tab === 1
                    ? PaginationUnderlineComplexHTML.default
                    : PaginationUnderlineComplexJsx.default
                }
                PaginationUnderlineElevated={
                  activeTabs[2].active_tab === 1
                    ? PaginationUnderlineElevatedHTML.default
                    : PaginationUnderlineElevatedJsx.default
                }
                PaginationUnderlineHelperText={
                  activeTabs[2].active_tab === 1
                    ? PaginationUnderlineHelperTextHTML.default
                    : PaginationUnderlineHelperTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Pagination Outline */}
          <h3 id="PaginationAllOutline">Pagination Outline</h3>
          <p>
            Staring from top, basic pagination, pagination with all options,
            pagination with helper text and elevated pagination.{" "}
          </p>
          <PreviewBlock
            id="PaginationAllOutline"
            HtmlComponent={PaginationOutlineBasicHTML.default}
            JsxComponent={PaginationOutlineBasicJsx.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <PaginationAllOutlinePreview
                PaginationOutlineBasic={
                  activeTabs[3].active_tab === 1
                    ? PaginationOutlineBasicHTML.default
                    : PaginationOutlineBasicJsx.default
                }
                PaginationOutlineComplex={
                  activeTabs[3].active_tab === 1
                    ? PaginationOutlineComplexHTML.default
                    : PaginationOutlineComplexJsx.default
                }
                PaginationOutlineElevated={
                  activeTabs[3].active_tab === 1
                    ? PaginationOutlineElevatedHTML.default
                    : PaginationOutlineElevatedJsx.default
                }
                PaginationOutlineHelperText={
                  activeTabs[3].active_tab === 1
                    ? PaginationOutlineHelperTextHTML.default
                    : PaginationOutlineHelperTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* BreadCrumbs Flat Icon Section */}
          <h3 id="PaginationAllMinimal">Pagination Minimal</h3>

          <PreviewBlock
            id="PaginationAllMinimal"
            HtmlComponent={PaginationMinimalBasicIconsTextHTML.default}
            JsxComponent={PaginationMinimalBasicIconsTextJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <PaginationAllMinimalPreview
                PaginationMinimalBasicIconsText={
                  activeTabs[4].active_tab === 1
                    ? PaginationMinimalBasicIconsTextHTML.default
                    : PaginationMinimalBasicIconsTextJsx.default
                }
                PaginationMinimalBasicIcons={
                  activeTabs[4].active_tab === 1
                    ? PaginationMinimalBasicIconsHTML.default
                    : PaginationMinimalBasicIconsJsx.default
                }
                PaginationMinimalOutlineIconsText={
                  activeTabs[4].active_tab === 1
                    ? PaginationMinimalOutlineIconsTextHTML.default
                    : PaginationMinimalOutlineIconsTextJsx.default
                }
                PaginationMinimalOutlineIcons={
                  activeTabs[4].active_tab === 1
                    ? PaginationMinimalOutlineIconsHTML.default
                    : PaginationMinimalOutlineIconsJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Pagination consists of a list of links that help a user visualize
            the current location within a set of content. Use the pagination
            component to allow users to navigate to other pages, to the previous
            or next page and even to the first or last pages. On small screens,
            only the links to the previous and next page are showing.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Pagination uses the nav element with an inner list with a set of
            links. It comes in 4 styles, basic, outline, underline top and
            underline bottom. The links can contain icons and text:
          </p>
          <ul>
            <li>
              <strong>Links:</strong> 0.875rem (14px) font size and 1rem (16px)
              horizontal padding.
            </li>
            <li>
              <strong>Icons:</strong> 1em height (16px) pixel with and height.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              The pagination links are wrapped <code>&lt;nav&gt;</code> element
              with a <code>role=navigation</code> and aria-label to make it
              clearer to users that this navigation landmark is intended for the
              pagination.
            </li>
            <li>
              By using aria-label, we can add a meaningful label to each link,
              so instead of hearing the screen reader saying "Link, 1" it will
              be "Link, Goto Page 1".
            </li>
            <li>
              To indicate which element is active, the aria-current label is
              used like so:{" "}
              <code>
                aria-label=&quot;Current Page, Page 3&quot;
                aria-current=&quot;true&quot;
              </code>
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default PaginationPage
