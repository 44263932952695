import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function PaginationAllOutlinePreview(props) {
  const {
    PaginationOutlineBasic,
    PaginationOutlineComplex,
    PaginationOutlineElevated,
    PaginationOutlineHelperText,
  } = props

  return (
    <div className="flex w-full flex-col items-center justify-center gap-20 ">
      {/*<!-- Component: All outline pagination --> */}
      {/*<!-- Basic --> */}
      <CopyComponent
        copyToClipboardCode={PaginationOutlineBasic}
        componentName="PaginationOutlineBasic"
      >
        <nav role="navigation" aria-label="Pagination Navigation">
          <ul className="flex list-none items-center justify-center divide-x divide-slate-200 overflow-hidden rounded border border-slate-200 text-sm text-slate-700">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-35 desc-35"
                >
                  <title id="title-35">Previous page</title>
                  <desc id="desc-35">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap bg-emerald-500 px-4 text-sm font-medium text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-36 desc-36"
                >
                  <title id="title-36">Next page</title>
                  <desc id="desc-36">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- Complex --> */}
      <CopyComponent
        copyToClipboardCode={PaginationOutlineComplex}
        componentName="PaginationOutlineComplex"
      >
        <nav role="navigation" aria-label="Pagination Navigation">
          <ul className="flex list-none items-center justify-center divide-x divide-slate-200 overflow-hidden rounded border border-slate-200 text-sm text-slate-700">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">First</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-37 desc-37"
                >
                  <title id="title-37">First page</title>
                  <desc id="desc-37">link to first page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Previous</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-38 desc-38"
                >
                  <title id="title-38">Previous page</title>
                  <desc id="desc-38">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Current Page, Page 2"
                aria-current="true"
                className="hidden h-10 items-center justify-center whitespace-nowrap bg-emerald-500 px-4 text-sm font-medium text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
                aria-label="Goto Page 3"
              >
                3
              </a>
            </li>
            <li>
              <span
                href="javascript:void(0)"
                className="pointer-events-none hidden h-10 items-center justify-center px-4 text-sm font-medium text-slate-700 focus-visible:outline-none md:inline-flex"
              >
                ...
              </span>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 97"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                97
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 98"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                98
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 99"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                99
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-39 desc-39"
                >
                  <title id="title-39">Next page</title>
                  <desc id="desc-39">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 99"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Last</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-40 desc-40"
                >
                  <title id="title-40">Last page</title>
                  <desc id="desc-40">link to last page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- With helper text --> */}
      <CopyComponent
        copyToClipboardCode={PaginationOutlineHelperText}
        componentName="PaginationOutlineHelperText"
      >
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          className="flex w-full flex-col items-center gap-2 md:flex-row"
        >
          <p className="flex-1 text-center text-sm text-slate-500 md:text-left">
            Showing 31 to 40 of 57 results
          </p>
          <ul className="flex list-none items-center justify-center divide-x divide-slate-200 overflow-hidden rounded border border-slate-200 text-sm text-slate-700">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-41 desc-41"
                >
                  <title id="title-41">Previous page</title>
                  <desc id="desc-41">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap bg-emerald-500 px-4 text-sm font-medium text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-42 desc-42"
                >
                  <title id="title-42">Next</title>
                  <desc id="desc-42">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- Elevated --> */}
      <CopyComponent
        copyToClipboardCode={PaginationOutlineElevated}
        componentName="PaginationOutlineElevated"
      >
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          className="flex items-center overflow-hidden rounded border border-slate-200 shadow-md shadow-slate-200"
        >
          <ul className="flex list-none items-center justify-center divide-x divide-slate-200 text-sm text-slate-700">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-43 desc-43"
                >
                  <title id="title-43">Previous page</title>
                  <desc id="desc-43">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap bg-emerald-500 px-4 text-sm font-medium text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:text-emerald-500 focus:bg-emerald-50 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-44 desc-44"
                >
                  <title id="title-44">Next page</title>
                  <desc id="desc-44">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>
      {/*<!-- End All outline pagination --> */}
    </div>
  )
}
