import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function PaginationAllOverlinePreview(props) {
  const {
    PaginationOverlineBasic,
    PaginationOverlineComplex,
    PaginationOverlineElevated,
    PaginationOverlineHelperText,
  } = props

  return (
    <div className="flex w-full flex-col items-center justify-center gap-20 ">
      {/*<!-- Component: All overline pagination --> */}
      {/*<!-- Basic --> */}
      <CopyComponent
        copyToClipboardCode={PaginationOverlineBasic}
        componentName="PaginationOverlineBasic"
      >
        <nav role="navigation" aria-label="Pagination Navigation">
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-25 desc-25"
                >
                  <title id="title-25">Previous page</title>
                  <desc id="desc-25">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap border-t border-t-emerald-500 px-4 text-sm font-medium text-emerald-500 transition duration-300 hover:border-t-emerald-600 hover:bg-emerald-50 hover:text-emerald-600 focus:border-t-emerald-700 focus:bg-emerald-50 focus:text-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-26 desc-26"
                >
                  <title id="title-26">Next page</title>
                  <desc id="desc-26">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- Complex --> */}
      <CopyComponent
        copyToClipboardCode={PaginationOverlineComplex}
        componentName="PaginationOverlineComplex"
      >
        <nav role="navigation" aria-label="Pagination Navigation">
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">First</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-27 desc-27"
                >
                  <title id="title-27">First page</title>
                  <desc id="desc-27">link to first page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Previous</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-28 desc-28"
                >
                  <title id="title-28">Previous page</title>
                  <desc id="desc-28">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Current Page, Page 2"
                aria-current="true"
                className="hidden h-10 items-center justify-center whitespace-nowrap border-t border-t-emerald-500 px-4 text-sm font-medium text-emerald-500 transition duration-300 hover:border-t-emerald-600 hover:bg-emerald-50 hover:text-emerald-600 focus:border-t-emerald-700 focus:bg-emerald-50 focus:text-emerald-700 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
                aria-label="Goto Page 3"
              >
                3
              </a>
            </li>
            <li>
              <span
                href="javascript:void(0)"
                className="pointer-events-none hidden h-10 items-center justify-center px-4 text-sm font-medium text-slate-700 focus-visible:outline-none md:inline-flex"
              >
                ...
              </span>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 97"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                97
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 98"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                98
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 99"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                99
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-29 desc-29"
                >
                  <title id="title-29">Next page</title>
                  <desc id="desc-29">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 99"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="sr-only">Last</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-30 desc-30"
                >
                  <title id="title-30">Last page</title>
                  <desc id="desc-30">link to last page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- With helper text --> */}
      <CopyComponent
        copyToClipboardCode={PaginationOverlineHelperText}
        componentName="PaginationOverlineHelperText"
      >
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          className="flex w-full flex-col items-center gap-2 md:flex-row"
        >
          <p className="flex-1 text-center text-sm text-slate-500 md:text-left">
            Showing 31 to 40 of 57 results
          </p>
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-31 desc-31"
                >
                  <title id="title-31">Previous page</title>
                  <desc id="desc-31">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap border-t border-t-emerald-500 px-4 text-sm font-medium text-emerald-500 transition duration-300 hover:border-t-emerald-600 hover:bg-emerald-50 hover:text-emerald-600 focus:border-t-emerald-700 focus:bg-emerald-50 focus:text-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-32 desc-32"
                >
                  <title id="title-32">Next</title>
                  <desc id="desc-32">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>

      {/*<!-- Elevated --> */}
      <CopyComponent
        copyToClipboardCode={PaginationOverlineElevated}
        componentName="PaginationOverlineElevated"
      >
        <nav
          role="navigation"
          aria-label="Pagination Navigation"
          className="flex items-center overflow-hidden rounded shadow-md shadow-slate-200"
        >
          <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="order-2 md:sr-only">Prev</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-33 desc-33"
                >
                  <title id="title-33">Previous page</title>
                  <desc id="desc-33">link to previous page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 1"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 2"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="hidden h-10 items-center justify-center whitespace-nowrap border-t border-t-emerald-500 px-4 text-sm font-medium text-emerald-500 transition duration-300 hover:border-t-emerald-600 hover:bg-emerald-50 hover:text-emerald-600 focus:border-t-emerald-700 focus:bg-emerald-50 focus:text-emerald-700 focus-visible:outline-none md:inline-flex"
                aria-label="Current Page, Page 3"
                aria-current="true"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 5"
                className="hidden h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none md:inline-flex"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                aria-label="Goto Page 4"
                className="inline-flex h-10 items-center justify-center gap-4 stroke-slate-700 px-4 text-sm font-medium text-slate-700 transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-500 hover:text-emerald-500 focus:bg-emerald-50 focus:stroke-emerald-600 focus:text-emerald-600 focus-visible:outline-none"
              >
                <span className="md:sr-only">Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="-mx-1 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-34 desc-34"
                >
                  <title id="title-34">Next page</title>
                  <desc id="desc-34">link to next page</desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </CopyComponent>
      {/*<!-- End All overline pagination --> */}
    </div>
  )
}
